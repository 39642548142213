// Zustand
import { create } from 'zustand'
import useLanguageStore from './languageStore'

// API
import apiProtected from '../api/apiProtected'
import apiPublic from '../api/apiPublic'

// Utils
import { getLocalStorage, setLocalStorage } from '../ruya-shared/shared/utils/storageHelper'
import { errorHandler } from '../ruya-shared/shared/utils/errorHelper'

// Types
import type { IUser } from '../ruya-shared/shared/types'
import type { IUserDataState, IUserResponse, IUserState } from '../@types/user'
import type { ApiResponse } from '../@types/global'

// Config
import { commonSettings } from '../ruya-shared/shared/config/commonSettings'

// Initial State
const initialState: IUserDataState = {
	user: null,
	loading: false,
	error: null
}

// User Store
const useUserStore = create<IUserState>(set => ({
	...initialState,
	// Actions
	setLoading: loading =>
		set({
			loading,
			error: null
		}),
	setApiError: error =>
		set({
			loading: false,
			error
		}),
	reset: () => set(initialState),
	// Async actions
	authenticateUser: async credentials => {
		// Set loading to true
		set({ loading: true })

		try {
			// Authenticate user
			const { data } = await apiPublic.post('/auth/login', {
				email: credentials?.email,
				password: credentials?.password,
				captchaToken: credentials?.captchaToken
			})

			// Axios data response
			const userResponse = data as ApiResponse<IUserResponse>

			if (userResponse.status === 'success' && userResponse.data) {
				// Save token to local storage
				setLocalStorage(commonSettings.apps.api.tokenName, userResponse.data.token)

				// Save user to state
				set({ user: userResponse.data.user, loading: false, error: null })

				// Set user language with languageStore
				useLanguageStore.getState().setLanguage(userResponse.data.user.language)

				return true
			}
			set({ loading: false, error: userResponse.message })
			return false
		} catch (error) {
			const errorMsg = errorHandler(error)
			set({ loading: false, error: errorMsg })
			return false
		}
	},
	authenticateUserWithToken: async () => {
		// Set loading to true
		set({ loading: true })

		// Check if token exists
		const token = getLocalStorage(commonSettings.apps.api.tokenName)

		if (token) {
			try {
				// Get user with token
				const { data } = await apiProtected.get('/user')

				// Axios data response
				const userResponse = data as ApiResponse<IUser>

				// Save user to state
				if (userResponse.status === 'success' && userResponse.data) {
					set({ user: userResponse.data, loading: false, error: null })
				} else {
					set({ loading: false, error: userResponse.message })
				}
			} catch (error) {
				const errorMsg = errorHandler(error)
				set({ loading: false, error: errorMsg })

				// Logout user
				useUserStore.getState().logout()
			}
		} else {
			set({ loading: false })
		}
	},
	authenticateUserWithFacebook: async () => {
		// Get current language
		const currentLanguage = useLanguageStore.getState().selectedLanguage?.isoCode

		// Replace with your actual backend URL
		const backendUrl = `${commonSettings.apps.api.url}/auth/facebook?lang=${currentLanguage}`
		window.location.href = backendUrl
	},
	authenticateUserWithGoogle: async () => {
		// Get current language
		const currentLanguage = useLanguageStore.getState().selectedLanguage?.isoCode

		// Replace with your actual backend URL
		const backendUrl = `${commonSettings.apps.api.url}/auth/google?lang=${currentLanguage}`
		window.location.href = backendUrl
	},
	authenticateUserApple: async () => {
		// Get current language
		const currentLanguage = useLanguageStore.getState().selectedLanguage?.isoCode

		// Replace with your actual backend URL
		const backendUrl = `${commonSettings.apps.api.url}/auth/apple?lang=${currentLanguage}`
		window.location.href = backendUrl
	},
	updateUserLanguage: isoCode => {
		set(state => ({
			...state,
			user: state.user ? { ...state.user, language: isoCode } : null
		}))
	},
	getUserInfoList: async () => {
		// Set loading to true
		set({ loading: true })

		try {
			// Make the API call
			const { data } = await apiProtected.get('/user/info')

			// Axios data response
			const userInfo = data as ApiResponse

			if (userInfo.status === 'success') {
				return userInfo.data
			}

			return []
		} catch (error) {
			const errorMsg = errorHandler(error)
			set({ loading: false, error: errorMsg })

			return []
		}
	},
	updateUserInfoList: async infoList => {
		// Set loading to true
		set({ loading: true })

		try {
			// Make the API call
			const { data } = await apiProtected.patch('/user/info', {
				infoList
			})

			// Axios data response
			const response = data as ApiResponse<IUser>

			if (response.status === 'success') {
				// Update user in state
				set({ user: response.data, loading: false, error: null })

				return true
			}

			set({ loading: false, error: response.message })

			return false
		} catch (error) {
			const errorMsg = errorHandler(error)
			set({ loading: false, error: errorMsg })

			return false
		}
	},
	registerUser: async credentials => {
		// Set loading to true
		set({ loading: true })

		try {
			// Authenticate user
			const { data } = await apiPublic.post('/auth/sign-up', {
				displayName: credentials?.displayName,
				email: credentials?.email,
				password: credentials?.password,
				captchaToken: credentials?.captchaToken,
				verificationURL: commonSettings.apps.web.url + commonSettings.apps.web.paths.activation
			})

			// Axios data response
			const userResponse = data as ApiResponse<IUserResponse>

			if (userResponse.status === 'success' && userResponse.data) {
				// Save token to local storage
				setLocalStorage(commonSettings.apps.api.tokenName, userResponse.data.token)

				// Save user to state
				set({ user: userResponse.data.user, loading: false, error: null })

				return true
			}
			set({ loading: false, error: userResponse.message })

			return false
		} catch (error) {
			const errorMsg = errorHandler(error)
			set({ loading: false, error: errorMsg })
			return false
		}
	},
	forgotPassword: async credentials => {
		// Set loading to true
		set({ loading: true })

		try {
			// Make the API call
			const { data } = await apiPublic.post('/auth/forgot-password', {
				email: credentials?.email,
				captchaToken: credentials?.captchaToken,
				resetURL: commonSettings.apps.web.url + commonSettings.apps.web.paths.resetPassword
			})

			// Axios data response
			const response = data as ApiResponse

			if (response.status === 'success') {
				set({ loading: false, error: null })
				return true // Successful password change
			}
			set({ loading: false, error: response.message })
			return false // Password change failed
		} catch (error) {
			const errorMsg = errorHandler(error)
			set({ loading: false, error: errorMsg })

			return false
		}
	},
	resetPassword: async credentials => {
		// Set loading to true
		set({ loading: true })

		try {
			const { data } = await apiPublic.patch('/auth/reset-password', {
				newPassword: credentials.newPassword,
				resetToken: credentials.resetToken,
				captchaToken: credentials?.captchaToken
			})

			// Axios data response
			const response = data as ApiResponse

			// Update the token in local storage if it changes
			setLocalStorage(commonSettings.apps.api.tokenName, response.data)

			set({ loading: false, error: null })

			return true // Successful password change
		} catch (error) {
			const errorMsg = errorHandler(error)
			set({ loading: false, error: errorMsg })

			return false
		}
	},
	changePassword: async credentials => {
		// Set loading to true
		set({ loading: true })

		try {
			// Make the API call
			const { data } = await apiProtected.patch('/auth/change-password', {
				password: credentials.password,
				newPassword: credentials.newPassword
			})

			// Axios data response
			const response = data as ApiResponse

			if (response.status === 'success') {
				// Update the token in local storage if it changes
				setLocalStorage(commonSettings.apps.api.tokenName, response.data)

				set({ loading: false, error: null })

				return true // Successful password change
			}
			set({ loading: false, error: response.message })
			return false // Password change failed
		} catch (error) {
			const errorMsg = errorHandler(error)
			set({ loading: false, error: errorMsg })
			return false // Password change failed
		}
	},
	activateUser: async token => {
		// Set loading to true
		set({ loading: true })

		try {
			// Make the API call
			const { data } = await apiPublic.patch(`/auth/activation/${token}`)

			// Axios data response
			const response = data as ApiResponse

			// Update the token in local storage if it changes
			setLocalStorage(commonSettings.apps.api.tokenName, response.data)

			set({ loading: false, error: null })

			return true // Successful activation
		} catch (error) {
			const errorMsg = errorHandler(error)
			set({ loading: false, error: errorMsg })
			return false // Activation failed
		}
	},
	logout: () => {
		// Remove token from local storage
		setLocalStorage(commonSettings.apps.api.tokenName, '')

		// Reset user state
		useUserStore.getState().reset()

		// Navigate user to login page
		window.location.href = commonSettings.apps.web.paths.login
	}
}))

export default useUserStore
