// Zustand
import { create } from 'zustand'

// Store
import useLanguageStore from './languageStore'

// API
import apiProtected from '../api/apiProtected'

// Utils
import { errorHandler } from '../ruya-shared/shared/utils/errorHelper'

// Types
import type { InterpreterDataState, InterpreterState } from '../@types/interpreter'

const initialState: InterpreterDataState = {
	interpreterList: [],
	loading: false,
	error: null
}

const useInterpreterStore = create<InterpreterState>((set, get) => ({
	...initialState,
	setLoading: loading => set({ loading }),
	setApiError: error => set({ error }),
	loadInterpreterList: async () => {
		// Set loading
		set({ loading: true })

		// If psychologist list is already loaded, return
		if (get().interpreterList.length > 0) return

		try {
			const { data, status } = await apiProtected.get('/interpreter/' + useLanguageStore.getState().selectedLanguage?.isoCode)

			// Check axios error
			if (status !== 200) set({ error: 'Error fetching interpreter list', loading: false })

			// Check API error
			if (data.status !== 'success') set({ error: data.message, loading: false })

			set({ interpreterList: data.data, loading: false })
		} catch (error) {
			set({ error: errorHandler(error), loading: false })
		}
	}
}))

export default useInterpreterStore
