import { useState } from 'react'
import './NavigationMobile.scss'

// Atoms
import Logo from '../../../ruya-shared/shared/ui/atoms/logo/Logo'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

// Translation
import { useTranslation } from 'react-i18next'

// Router
import { NavLink } from 'react-router-dom'

// Store
import useNavigationStore from '../../../store/navigationStore'
import useLanguageStore from '../../../store/languageStore'

// Config
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

const NavigationMobile = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Load navigation data
	const navigationStore = useNavigationStore()
	const languageStore = useLanguageStore()

	// Local state
	const [navigationMobile, setNavigationMobile] = useState<boolean>(false)

	const toggleMobileNavigation = () => {
		setNavigationMobile(!navigationMobile)
	}

	const languagePath =
		languageStore.selectedLanguage?.isoCode === languageSettings.defaultLanguage ? '' : `/${languageStore.selectedLanguage?.isoCode}`

	return (
		<>
			{navigationMobile && (
				<div className="NavigationMobile">
					<div className="NavigationMobile_Top">
						<Logo />

						<div className="NavigationMobile_MobileButton" onClick={toggleMobileNavigation}>
							<MaterialSymbol name="close" />
						</div>
					</div>

					<ul className="NavigationMobile_Menu">
						<li className="NavigationMobile_Menu_Item">
							<a href={`${commonSettings.apps.next.url}${languagePath}`}>
								<MaterialSymbol name="home" />
								<span>{t('navigation:home')}</span>
							</a>
						</li>
						<li className="NavigationMobile_Menu_Item">
							<a href={`${commonSettings.apps.next.url}${languagePath}/articles`} onClick={toggleMobileNavigation}>
								<MaterialSymbol name="newsmode" />
								<span>{t('navigation:articles')}</span>
							</a>
						</li>
						<li className="NavigationMobile_Menu_Separator" />
						<li className="NavigationMobile_Menu_Item">
							<NavLink
								to={commonSettings.apps.web.paths.journal}
								onClick={toggleMobileNavigation}
								className={({ isActive }) => (isActive ? 'NavigationMobile_Menu_Item--active' : '')}>
								<MaterialSymbol name="book_5" />
								<span>{t('navigation:journal')}</span>
							</NavLink>
						</li>
						<li className="NavigationMobile_Menu_Item">
							<NavLink
								to={commonSettings.apps.web.paths.profile}
								onClick={toggleMobileNavigation}
								className={({ isActive }) => (isActive ? 'NavigationMobile_Menu_Item--active' : '')}>
								<MaterialSymbol name="user_attributes" />
								<span>{t('links:profileDetails')}</span>
							</NavLink>
						</li>
						<li className="NavigationMobile_Menu_Item">
							<NavLink
								to={commonSettings.apps.web.paths.changePassword}
								onClick={toggleMobileNavigation}
								className={({ isActive }) => (isActive ? 'NavigationMobile_Menu_Item--active' : '')}>
								<MaterialSymbol name="password" />
								<span>{t('links:changePassword')}</span>
							</NavLink>
						</li>
						<li className="NavigationMobile_Menu_Item">
							<NavLink
								to={commonSettings.apps.web.paths.subscription}
								onClick={toggleMobileNavigation}
								className={({ isActive }) => (isActive ? 'NavigationMobile_Menu_Item--active' : '')}>
								<MaterialSymbol name="card_membership" />
								<span>{t('links:manageSubscription')}</span>
							</NavLink>
						</li>
						<li className="NavigationMobile_Menu_Item">
							<NavLink
								to={commonSettings.apps.web.paths.loggedInLanguage}
								onClick={toggleMobileNavigation}
								className={({ isActive }) => (isActive ? 'NavigationMobile_Menu_Item--active' : '')}>
								<MaterialSymbol name="language" />
								<span>{t('links:changeLanguage')}</span>
							</NavLink>
						</li>
						<li className="NavigationMobile_Menu_Item">
							<NavLink
								to={commonSettings.apps.web.paths.logout}
								onClick={toggleMobileNavigation}
								className={({ isActive }) => (isActive ? 'NavigationMobile_Menu_Item--active' : '')}>
								<MaterialSymbol name="logout" />
								<span>{t('links:logout')}</span>
							</NavLink>
						</li>
					</ul>
				</div>
			)}

			<div className="NavigationMobile_MobileButton" onClick={toggleMobileNavigation}>
				<MaterialSymbol name="menu" />
			</div>
		</>
	)
}

export default NavigationMobile
