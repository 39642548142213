import './Header.scss'

// Zustand
import useUserStore from '../../../store/userStore'

// Localization
import { useTranslation } from 'react-i18next'

// Atoms
import Logo from '../../../ruya-shared/shared/ui/atoms/logo/Logo'

// Molecules
import AccountMenu from '../../molecules/accountMenu/AccountMenu'

// Organisms
import Navigation from '../navigation/Navigation'

const Header = () => {
	// Zustand
	const userStore = useUserStore()

	// Translation Hook
	const { t } = useTranslation()

	return (
		<header className="Header">
			<div className="Header_Content">
				<Logo navigateTo="/dashboard" />

				<Navigation />

				<div className="Header_User">
					<AccountMenu />
				</div>
			</div>
		</header>
	)
}

export default Header
