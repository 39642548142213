import './Interpreter.scss'

// Translation
import { useTranslation } from 'react-i18next'

// Types
import type { IInterpreter, ObjectId } from '../../../ruya-shared/shared/types'

// Atoms
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'

interface InterpreterProps {
	interpreter: IInterpreter
	handleInterpret: (id: ObjectId) => void
}

const Interpreter = (props: InterpreterProps) => {
	const { interpreter, handleInterpret } = props

	// Translation Hooks
	const { t } = useTranslation()

	return (
		<div className="Interpreter" key={interpreter._id?.toString()}>
			<MaterialSymbol name={interpreter?.icon} fill="1" />
			<div className="Interpreter_Explanation">
				<P size="lg">{interpreter?.name}</P>
				<P size="sm">{interpreter?.description}</P>
				<P size="sm">
					<span>{t('journal:theoryFocus')}</span> {interpreter?.focus}
				</P>
				<P size="sm">
					<span>{t('journal:theoryBenefit')}</span> {interpreter?.benefit}
				</P>
			</div>
			<Button
				onClick={() => handleInterpret(interpreter._id as ObjectId)}
				text={t('button:continue')}
				size="sm"
				color="light"
			/>
		</div>
	)
}

export default Interpreter
