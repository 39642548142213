import './Footer.scss'

// Localization
import { useTranslation } from 'react-i18next'

// Atoms
import Logo from '../../../ruya-shared/shared/ui/atoms/logo/Logo'

// Molecules
import CurrentLanguageButton from '../../molecules/currentLanguageButton/CurrentLanguageButton'

// Store
import useNavigationStore from '../../../store/navigationStore'

// Config
import { socialSettings } from '../../../ruya-shared/shared/config/socialSettings'

// Types
import type { INavigationItem } from '../../../ruya-shared/shared/types'

const Footer = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Load navigation data
	const navigationStore = useNavigationStore()

	return (
		<footer className="Footer">
			<div className="Footer_Content">
				<div className="Footer_Logo">
					<Logo />
				</div>
				<div className="Footer_Center">
					<div className="Footer_Social">
						<a href={socialSettings.facebook.url} target="_blank" title={socialSettings.facebook.title} rel="noreferrer">
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
									<title>{socialSettings.facebook.title}</title>
									<path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
								</svg>
							</span>
						</a>
						<a href={socialSettings.instagram.url} target="_blank" title={socialSettings.instagram.title} rel="noreferrer">
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<title>{socialSettings.instagram.title}</title>
									<path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
								</svg>
							</span>
						</a>
						<a href={socialSettings.linkedIn.url} target="_blank" title={socialSettings.linkedIn.title} rel="noreferrer">
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<title>{socialSettings.linkedIn.title}</title>
									<path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
								</svg>
							</span>
						</a>
						<a href={socialSettings.pinterest.url} target="_blank" title={socialSettings.pinterest.title} rel="noreferrer">
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
									<title>{socialSettings.pinterest.title}</title>
									<path d="M204 6.5C101.4 6.5 0 74.9 0 185.6 0 256 39.6 296 63.6 296c9.9 0 15.6-27.6 15.6-35.4 0-9.3-23.7-29.1-23.7-67.8 0-80.4 61.2-137.4 140.4-137.4 68.1 0 118.5 38.7 118.5 109.8 0 53.1-21.3 152.7-90.3 152.7-24.9 0-46.2-18-46.2-43.8 0-37.8 26.4-74.4 26.4-113.4 0-66.2-93.9-54.2-93.9 25.8 0 16.8 2.1 35.4 9.6 50.7-13.8 59.4-42 147.9-42 209.1 0 18.9 2.7 37.5 4.5 56.4 3.4 3.8 1.7 3.4 6.9 1.5 50.4-69 48.6-82.5 71.4-172.8 12.3 23.4 44.1 36 69.3 36 106.2 0 153.9-103.5 153.9-196.8C384 71.3 298.2 6.5 204 6.5z" />
								</svg>
							</span>
						</a>
						<a href={socialSettings.tikTok.url} target="_blank" title={socialSettings.tikTok.title} rel="noreferrer">
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<title>{socialSettings.tikTok.title}</title>
									<path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
								</svg>
							</span>
						</a>
						<a href={socialSettings.youTube.url} target="_blank" title={socialSettings.youTube.title} rel="noreferrer">
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
									<title>{socialSettings.youTube.title}</title>
									<path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" />
								</svg>
							</span>
						</a>
						<a href={socialSettings.twitter.url} target="_blank" title={socialSettings.twitter.title} rel="noreferrer">
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<title>{socialSettings.twitter.title}</title>
									<path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
								</svg>
							</span>
						</a>
					</div>

					<ul className="Footer_Navigation">
						{navigationStore.footerNavigation?.map((link: INavigationItem) => (
							<li key={`Footer_${link._id}`}>
								<a href={`https://ruya.co/pages/${link.slug}`}>{link.linkLabel}</a>
							</li>
						))}
						<li>
							<a href="https://ruya.co/pages/manage-cookies">{t('navigation:manageCookies')}</a>
						</li>
					</ul>
					<div className="Footer_CopyrightText">
						<p>{t('common:copyrightText', { year: new Date().getFullYear() })}</p>
					</div>
				</div>
				<div className="Footer_Language">
					<CurrentLanguageButton />
				</div>
			</div>
		</footer>
	)
}

export default Footer
