// Zustand
import { create } from 'zustand'

// API
import apiProtected from '../api/apiProtected'

// Store
import useLanguageStore from './languageStore'

// Shared
import languageSettings from '../ruya-shared/shared/config/languageSettings'
import { errorHandler } from '../ruya-shared/shared/utils/errorHelper'

// Types
import type { NavigationDataState, NavigationState } from '../@types/navigation'

const initialState: NavigationDataState = {
	headerNavigation: [],
	footerNavigation: [],
	mobileNavigation: [],
	lang: languageSettings.defaultLanguage,
	loading: false,
	error: null
}

const useNavigationStore = create<NavigationState>((set, get) => ({
	...initialState,
	loadNavigations: async () => {
		// Set loading
		set({ loading: true })

		const lang = useLanguageStore.getState().selectedLanguage

		// If language is same with the previous one, and the navigation data is already loaded, return
		if (
			lang?.isoCode === get().lang &&
			get().headerNavigation.length > 0 &&
			get().footerNavigation.length > 0 &&
			get().mobileNavigation.length > 0
		) {
			set({ loading: false })
			return
		}

		try {
			// Fetch all navigation data simultaneously
			const [headerNavResponse, footerNavResponse, mobileNavResponse] = await Promise.all([
				apiProtected.get(`/navigation?lang=${lang?.isoCode}&header=true`),
				apiProtected.get(`/navigation?lang=${lang?.isoCode}&footer=true`),
				apiProtected.get(`/navigation?lang=${lang?.isoCode}&header=true&footer=true`)
			])

			// Check axios error
			if (headerNavResponse.status !== 200 || footerNavResponse.status !== 200 || mobileNavResponse.status !== 200) {
				set({ error: 'Error fetching navigation', loading: false })
				return
			}

			// Check API error
			if (
				headerNavResponse.data.status !== 'success' ||
				footerNavResponse.data.status !== 'success' ||
				mobileNavResponse.data.status !== 'success'
			) {
				set({ error: headerNavResponse.data.message, loading: false })
				return
			}

			// Update the state with the fetched data
			set({
				headerNavigation: headerNavResponse.data.data,
				footerNavigation: footerNavResponse.data.data,
				mobileNavigation: mobileNavResponse.data.data,
				loading: false,
				error: null
			})
		} catch (error) {
			set({ error: errorHandler(error), loading: false })
		}
	},
	setLoading: (loading: boolean) => set({ loading }),
	setApiError: (error: string) => set({ error })
}))

export default useNavigationStore
