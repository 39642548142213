// Regular expression to validate email
export const emailRegExp =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// Regular expression to validate for numbers only
export const numberOnlyRegEx = /^[0-9]+$/

// Regular expression to validate phone number
export const phoneRegExp = /^\+?[\d ]+$/

// Regular expression to validate vat number
export const vatNumberRegEx = /^([A-Z]{2})([0-9]{9,12})$/

// Regular expression to validate string with no whitespace wrapping
export const whitespaceWrappingTextRegEx = /^\s+|\s+$/gm

// Regular expression to validate iso language code
export const isoLanguageCodeRegEx = /^[a-z]{2}(-[A-Z]{2})?$/

// Regular expression to validate display name. Small letters, numbers and underscore only. 3-15 characters.
export const displayNameRegEx = /^[a-z0-9_-]{3,15}$/

// Regular expression to validate url slug. Small letters, numbers and hyphen only. Must start and end with small letter or number. 3-15 characters.
export const urlSlugRegEx = /^[a-z0-9]+(-[a-z0-9]+)*$/

// Image name regular expression (small letters, numbers, hyphen, dot only)
export const imageNameRegEx = /^[a-z0-9.-]+$/

// File name regular expression (small letters, numbers, hyphen, dot only)
export const fileNameRegEx = /^[a-z0-9.-]+$/

// AWS S3 Folder name regular expression (small letters, numbers, hyphen, slash only)
export const folderNameRegEx = /^([a-z0-9-]+\/)+$/

// Regular expression to validate url
export const urlRegEx = /^(https?):\/\/[^\s/$.?#].[^\s]*$/
