import { useState, useEffect } from 'react'
import './SleepQualitySelector.scss'

// Translation
import { useTranslation } from 'react-i18next'

// Atoms
import Tooltip from '../tooltip/Tooltip'
import MaterialSymbol from '../materialSymbol/MaterialSymbol'

interface SleepQualitySelectorProps {
	icon?: string
	label?: string
	value: number
	onChange: (value: number) => void
	className?: string
	showTooltip?: boolean
	tooltipText?: string
}

interface SleepQualityOption {
	value: number
	text: string
	icon: string
}

const SleepQualitySelector = (props: SleepQualitySelectorProps) => {
	// Destruct props
	const { 
		icon,
		label,
		value = 3,
		onChange, 
		className, 
		showTooltip = false,
		tooltipText = '', 
		...rest 
	} = props

	// Local State
	const [selected, setSelected] = useState<number>(value)

	useEffect(() => {
		setSelected(value)
	}, [value])

	// Classes
	const classNames = 'SleepQualitySelector' + `${className !== undefined ? ' ' + className : ''}`

	// Translation Hooks
	const { t: optionsT } = useTranslation('options')
	const { t } = useTranslation()

	const options: SleepQualityOption[] = [
		{ value: 1, text: optionsT('Poor'), icon: '🙁' },
		{ value: 2, text: optionsT('Fair'), icon: '😐' },
		{ value: 3, text: optionsT('Good'), icon: '🙂' },
		{ value: 4, text: optionsT('Very Good'), icon: '😄' },
		{ value: 5, text: optionsT('Excellent'), icon: '🤩' }
	]

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = Number.parseInt(e.target.value)
		setSelected(value)
		if (onChange !== undefined) onChange(value)
	}

	return (
		<div className={classNames}>
			{(label || icon || showTooltip) && (
				<div className="SleepQualitySelector_Label">
					{icon && <MaterialSymbol name={icon} fill="1" />}
					{label && <label>{label}</label>}
					{showTooltip && (
						<Tooltip content={tooltipText} disabled={tooltipText.length <= 0}>
							<MaterialSymbol className="SleepQualitySelector_Label_Help" name="help" fill="1" />
						</Tooltip>
					)}
				</div>
			)}
			<div className="SleepQualitySelector_Options">
				{options.map((option: SleepQualityOption) => (
					<Tooltip content={option.text} key={option.value}>
						<label className={`SleepQualitySelector_Option${option.value === selected ? ' SleepQualitySelector_Option--selected' : ''}`}>
							<input type="radio" name="SleepQuality" value={option.value} onChange={handleChange} />
							<span>{option.icon}</span>
						</label>
					</Tooltip>
				))}
			</div>
		</div>
	)
}

export default SleepQualitySelector
