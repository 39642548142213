import { useEffect } from 'react'

// Localization
import { useTranslation } from 'react-i18next'

// Utils
import { getLanguageFont } from './languageHelper'

const FontLoader = () => {
	// Translation Hook
	const { i18n } = useTranslation()

	useEffect(() => {
		const loadFont = () => {
			// Get Font Info
			const fontInfo = getLanguageFont(i18n.language)

			if (fontInfo) {
				// Add font-loading class to the body to hide content initially
				document.body.classList.add('LoadingFonts')

				// Remove existing font link elements if any
				const existingLink = document.querySelector('#dynamic-font-link')
				if (existingLink) {
					existingLink.remove()
				}

				// Create link element for the font
				const linkFont = document.createElement('link')
				linkFont.id = 'dynamic-font-link'
				linkFont.rel = 'stylesheet'
				linkFont.href = fontInfo.fontURL
				document.head.appendChild(linkFont)

				// Create style element for the font
				const style = document.createElement('style')

				// Add icon font face
				const iconFontFace = `@font-face {font-family: 'Material Symbols Outlined';font-style: normal;font-weight: 100 700;src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v205/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsI.woff2) format('woff2');}`

				// Add font faces
				style.innerHTML = `${iconFontFace}${fontInfo.fontFace}:root { --default-font: ${fontInfo.fontName.replace(/"/g, '\\"')}; }`

				document.head.appendChild(style)

				document.fonts.ready
					.then(() => {
						document.body.classList.remove('LoadingFonts')
					})
					.catch(error => {
						console.warn('Font loading failed', error)
						// Fallback in case the fonts fail to load within 3 seconds
						setTimeout(() => {
							if (document.body.classList.contains('LoadingFonts')) {
								document.body.classList.remove('LoadingFonts')
							}
						}, 3000)
					})
			}
		}

		// Load the font
		loadFont()

		// Re-load the font when the language changes
		const handleLanguageChange = () => {
			loadFont()
		}

		i18n.on('languageChanged', handleLanguageChange)

		// Cleanup on unmount
		return () => {
			i18n.off('languageChanged', handleLanguageChange)
		}
	}, [i18n.language])

	return null
}

export default FontLoader
