import './PublicHeader.scss'

// Atoms
import Logo from '../../../ruya-shared/shared/ui/atoms/logo/Logo'

// Organisms
import PublicNavigation from '../publicNavigation/PublicNavigation'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate } from 'react-router-dom'

// Shared
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

const PublicHeader = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Router
	const navigate = useNavigate()

	const login = () => {
		navigate(commonSettings.apps.web.paths.login)
	}

	return (
		<header className="PublicHeader">
			<div className="PublicHeader_Content">
				<Logo />

				<PublicNavigation />
			</div>
		</header>
	)
}

export default PublicHeader
