import { useState, useEffect } from 'react'
import './DreamQA.scss'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'

// Store
import useJournalStore from '../../../store/journalStore'

// Types
import type { IDreamQA, IInterpretation } from '../../../ruya-shared/shared/types'

interface DreamQAProps {
	interpretation: IInterpretation
}

const DreamQA = (props: DreamQAProps) => {
	// Destructure props
	const { interpretation } = props

	// Store
	const journalStore = useJournalStore()

	// Local state
	const [localQA, setLocalQA] = useState<IDreamQA[]>([])
	const [questionIndex, setQuestionIndex] = useState<number>(0)
	const [editingIndex, setEditingIndex] = useState<number>(0)

	useEffect(() => {
		if (interpretation?.dreamQA) {
			// Find the first unanswered question index
			const firstUnansweredIndex = interpretation.dreamQA.findIndex((item: IDreamQA) => item.answer === '')
			const currentIndex = firstUnansweredIndex !== -1 ? firstUnansweredIndex : interpretation.dreamQA.length
			setLocalQA(interpretation.dreamQA)
			setQuestionIndex(currentIndex || 0)
			setEditingIndex(currentIndex || 0)
		}
	}, [])

	// Check if interpretation is done
	const isInterpreted = !!interpretation.interpretation

	const handleAnswer = async (index: number, answer: string | null) => {
		// If interpretation is not provided, return
		if (!interpretation?._id) return

		const success = await journalStore.answerQuestion(interpretation._id as string, index, answer)

		if (success) {
			// If answer is successful, update local state
			const updatedQA = [...localQA]
			updatedQA[index].answer = answer
			setLocalQA(updatedQA)

			// Find the next unanswered question
			const nextUnansweredIndex = updatedQA.findIndex((item, idx) => idx > index && item.answer === '')
			if (nextUnansweredIndex !== -1) {
				// If there's another unanswered question, set editing index to that
				setEditingIndex(nextUnansweredIndex)
				setQuestionIndex(nextUnansweredIndex)
			} else {
				setEditingIndex(updatedQA.length)
				setQuestionIndex(updatedQA.length)
			}
		}
	}

	const handleEdit = (index: number) => {
		setEditingIndex(index)
	}

	// Interpret dream
	const handleInterpretDream = async () => {
		// If interpretation is not provided, return
		if (!interpretation?._id) return

		const isSuccess = await journalStore.interpretDream(interpretation?._id as string)

		if (isSuccess) {
			console.log('Interpreted...')
		}
	}

	return (
		<div className="DreamQA">
			<div className="DreamQA_List">
				{localQA?.map((item, index) => {
					// Render only the questions that are before the current question index
					if (index > questionIndex) return

					if (index <= questionIndex) {
						return (
							<div className="DreamQA_QA" key={index}>
								<Question question={item.question} />
								{editingIndex === index || questionIndex === index ? (
									<AnswerEdit answer={item.answer} sendAnswer={answer => handleAnswer(index, answer)} />
								) : (
									<Answer
										isInterpreted={isInterpreted}
										answer={item.answer === null ? 'Not answered' : item.answer}
										editAnswer={() => handleEdit(index)}
									/>
								)}
							</div>
						)
					}
				})}
			</div>

			{!isInterpreted && questionIndex === localQA.length && !journalStore.interpreting && (
				<div className="DreamQA_Interpret">
					<div className="DreamQA_Interpret_Image" />
					<div className="DreamQA_Interpret_Text">
						Please review your answers and click the button below to interpret your dream.{' '}
						<Button onClick={handleInterpretDream}>Interpret</Button>
					</div>
				</div>
			)}

			{journalStore.interpreting && <Loading />}

			{isInterpreted && (
				<div className="DreamQA_Interpret">
					<div className="DreamQA_Interpret_Image" />
					<div className="DreamQA_Interpret_Text">{interpretation.interpretation}</div>
				</div>
			)}
		</div>
	)
}

const Question = (props: { question: string }) => {
	return (
		<div className="DreamQA_Question">
			<div className="DreamQA_Question_Image" />
			<div className="DreamQA_Question_Text">{props.question}</div>
		</div>
	)
}

interface AnswerEditProps {
	answer: string | null
	sendAnswer: (answer: string | null) => void
}

const AnswerEdit = (props: AnswerEditProps) => {
	const { answer, sendAnswer } = props

	// Local state
	const [localAnswer, setLocalAnswer] = useState<string>(answer || '')

	return (
		<div className="DreamQA_AnswerEdit">
			<div className="DreamQA_AnswerEdit_Text">
				<input type="text" value={localAnswer} onChange={e => setLocalAnswer(e.target.value)} />
				<Button type="button" onClick={() => sendAnswer(localAnswer === '' ? null : localAnswer)}>
					Send
				</Button>
			</div>
			<div className="DreamQA_AnswerEdit_Image" />
		</div>
	)
}

interface AnswerProps {
	answer: string
	isInterpreted: boolean
	editAnswer: () => void
}

const Answer = (props: AnswerProps) => {
	const { answer, isInterpreted, editAnswer } = props

	return (
		<div className="DreamQA_Answer">
			<div className="DreamQA_Answer_Text">
				{answer || 'No answer'}
				{!isInterpreted && (
					<Button type="button" onClick={editAnswer}>
						Edit
					</Button>
				)}
			</div>
			<div className="DreamQA_Answer_Image" />
		</div>
	)
}

export default DreamQA
