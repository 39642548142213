import { type SetStateAction, useEffect, useState } from 'react'
import './InterpreterSelection.scss'

// Template
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Store
import useJournalStore from '../../../store/journalStore'
import useInterpreterStore from '../../../store/interpreterStore'

// Atoms
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import { FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'

// Molecules
import Interpreter from '../../molecules/interpreter/Interpreter'

// Shared
import type { IInterpreter, ObjectId } from '../../../ruya-shared/shared/types'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

const InterpreterSelection = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const { sleepSessionId, dreamId } = useParams()
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()
	const interpreterStore = useInterpreterStore()

	// Check parameters
	useEffect(() => {
		// Sleep session Id is required
		if (!sleepSessionId || !dreamId) navigate('/journal')
	}, [dreamId, sleepSessionId])

	// Continue to dream QA
	const handleInterpret = (interpreterId: ObjectId) => {
		navigate(`${commonSettings.apps.web.paths.journal}/interpretation/${sleepSessionId}/${dreamId}/${interpreterId}`)
	}

	return (
		<ContentWrapper className="InterpreterSelection">
			<HistoryBackLink href={`/journal/dream/${sleepSessionId}/${dreamId}`} />

			<H1>{t('journal:interpretHeader')}</H1>
			<P className="InterpreterSelection_Explanation">{t('journal:interpretDescription')}</P>

			<div className="InterpreterSelection_Interpreters">
				{interpreterStore.interpreterList.map((interpreter: IInterpreter) => (
					<Interpreter key={interpreter._id?.toString()} interpreter={interpreter} handleInterpret={handleInterpret} />
				))}
			</div>

			{journalStore.error && (
				<FormGroup>
					<p className="InterpreterSelection_Error">{journalStore.error}</p>
				</FormGroup>
			)}
		</ContentWrapper>
	)
}

export default Private(InterpreterSelection)
