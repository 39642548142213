import { useEffect, useState } from 'react'
import './DreamInterpretation.scss'

// Template
import Private from '../../templates/private/Private'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Store
import useJournalStore from '../../../store/journalStore'

// Atoms
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'
import ReactLink from '../../../ruya-shared/shared/ui/molecules/reactLink/ReactLink'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import InfoBox from '../../../ruya-shared/shared/ui/atoms/infoBox/InfoBox'

// Organisms
import DreamQA from '../../organisms/dreamQA/DreamQA'

// Localization
import { useTranslation } from 'react-i18next'

// Types
import type { IDream, IInterpretation } from '../../../ruya-shared/shared/types'

const DreamInterpretation = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const { sleepSessionId, dreamId, interpretationId } = useParams()
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()

	// Local state
	const [dream, setDream] = useState<IDream>()
	const [interpretation, setInterpretation] = useState<IInterpretation>()

	// Check if all parameters passed correctly in useEffect
	useEffect(() => {
		if (!sleepSessionId || !dreamId || !interpretationId) {
			navigate('/journal')
		} else {
			// Load sleep session
			journalStore.loadSleepSession(sleepSessionId)
		}
	}, [sleepSessionId, dreamId, interpretationId, navigate])

	// When currentSleepSession loaded
	useEffect(() => {
		const tmpDream = journalStore?.currentSleepSession?.dreams?.find((dream: IDream) => dream._id === dreamId)
		// Set dream
		setDream(tmpDream)
		// Set interpretation
		const tmpInterpretation = tmpDream?.interpretations?.find((interpretation: IInterpretation) => interpretation._id === interpretationId)
		setInterpretation(tmpInterpretation)
	}, [journalStore.currentSleepSession])

	// Loading
	if (journalStore.loading) {
		return (
			<ContentWrapper className="DreamInterpretation">
				<H1 isCentered={true}>Dream Interpretation</H1>
				<Loading />
			</ContentWrapper>
		)
	}

	// Error
	if (journalStore.error)
		return (
			<ContentWrapper className="DreamInterpretation">
				<H1 isCentered={true}>Dream Interpretation</H1>
				<InfoBox type="error" icon="true" text={journalStore.error} />
			</ContentWrapper>
		)

	// No Records
	if (!journalStore.currentSleepSession)
		return (
			<ContentWrapper className="DreamInterpretation">
				<H1 isCentered={true}>Dream Interpretation</H1>
				<InfoBox type="error" icon="true" text={t('errors:noRecordsFound')} />
			</ContentWrapper>
		)

	return (
		<ContentWrapper className="DreamInterpretation">
			<H1 isCentered={true}>Dream Interpretation</H1>

			<ReactLink href={`/journal/view/${sleepSessionId}`} icon="arrow_back">
				{t('links:back')}
			</ReactLink>

			<div className="DreamInterpretation_Dream">
				<div className="DreamInterpretation_Dream_Header">
					<h3>{dream?.title}</h3>
					<p>{dream?.dream}</p>
				</div>
				<div className="DreamInterpretation_QA">{interpretation && <DreamQA interpretation={interpretation} />}</div>
			</div>
		</ContentWrapper>
	)
}

export default Private(DreamInterpretation)
