import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import './RangeSlider.scss'
import MaterialSymbol from '../materialSymbol/MaterialSymbol'
import Tooltip from '../tooltip/Tooltip'

interface RangeSliderProps {
	icon?: string
	label?: string
	valueLabel?: string
	min: number
	max: number
	step: number
	value: number
	defaultValue?: number
	showTooltip?: boolean
	tooltipText?: string
	onChange: (value: number) => void
	className?: string
}

const RangeSlider = forwardRef<HTMLInputElement, RangeSliderProps>((props, ref) => {
	const {
		icon,
		label,
		defaultValue = 8,
		valueLabel,
		min = 1,
		max = 100,
		step = 1,
		value = defaultValue,
		showTooltip,
		tooltipText = '',
		onChange,
		className,
		...rest
	} = props

	const sliderRef = useRef<HTMLDivElement>(null)
	const thumbRef = useRef<HTMLDivElement>(null)
	const [isDragging, setIsDragging] = useState(false)

	// Calculate thumb position
	const getThumbPosition = (val: number) => ((val - min) / (max - min)) * 100

	// State to store the thumb position
	const [thumbPosition, setThumbPosition] = useState(getThumbPosition(value))

	// Update thumb position when value changes
	useEffect(() => {
		setThumbPosition(getThumbPosition(value))
	}, [value, min, max])

	const startDrag = useCallback((e: MouseEvent | TouchEvent) => {
		e.preventDefault()
		setIsDragging(true)
	}, [])

	const stopDrag = useCallback(() => {
		setIsDragging(false)
	}, [])

	const onDrag = useCallback(
		(e: MouseEvent | TouchEvent) => {
			if (!isDragging || !sliderRef.current) return

			const slider = sliderRef.current.getBoundingClientRect()
			let clientX = 0

			if (e.type === 'touchmove' && e instanceof TouchEvent) {
				clientX = e.touches[0].clientX
			} else if (e instanceof MouseEvent) {
				clientX = e.clientX
			}

			const diff = clientX - slider.left
			const percentage = diff / slider.width
			const newValue = Math.round(min + (max - min) * percentage)
			onChange(Math.min(Math.max(newValue, min), max))
		},
		[isDragging, min, max, onChange]
	)

	// Add event listeners for drag
	useEffect(() => {
		if (isDragging) {
			window.addEventListener('mousemove', onDrag)
			window.addEventListener('touchmove', onDrag)
			window.addEventListener('mouseup', stopDrag)
			window.addEventListener('touchend', stopDrag)
		}

		return () => {
			window.removeEventListener('mousemove', onDrag)
			window.removeEventListener('touchmove', onDrag)
			window.removeEventListener('mouseup', stopDrag)
			window.removeEventListener('touchend', stopDrag)
		}
	}, [isDragging, onDrag, stopDrag])

	// Classes
	const classNames = 'RangeSlider' + `${className !== undefined ? ' ' + className : ''}`

	return (
		<div className={classNames} ref={sliderRef}>
			{(label || icon || showTooltip) && (
				<div className="RangeSlider_Label">
					{icon && <MaterialSymbol name={icon} fill="1" />}
					{label && <label>{label}</label>}
					{showTooltip && (
						<Tooltip content={tooltipText} disabled={tooltipText.length <= 0}>
							<MaterialSymbol className="Input_Label_Help" name="help" fill="1" />
						</Tooltip>
					)}
				</div>
			)}
			<div className="RangeSlider_UI">
				<div className="RangeSlider_UI_TrackBg" />
				<div className="RangeSlider_UI_BG">
					<div className="RangeSlider_UI_Track">
						<div className="RangeSlider_UI_Track-fill" style={{ width: `${thumbPosition}%` }} />
					</div>
					<div
						className="RangeSlider_UI_Thumb"
						ref={thumbRef}
						style={{ left: `${thumbPosition}%` }}
						onMouseDown={startDrag as unknown as React.MouseEventHandler<HTMLDivElement>}
						onTouchStart={startDrag as unknown as React.TouchEventHandler<HTMLDivElement>}>
						{valueLabel && <span>{valueLabel}</span>}
					</div>
				</div>
			</div>
			<input
				type="range"
				ref={ref}
				className="RangeSlider_Input"
				min={min}
				max={max}
				step={step}
				value={value}
				onChange={e => onChange(Number(e.target.value))}
				{...rest}
			/>
		</div>
	)
})

RangeSlider.displayName = 'RangeSlider'
export default RangeSlider
