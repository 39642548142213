import { forwardRef, type InputHTMLAttributes } from 'react'
import './Input.scss'

// Atoms
import Tooltip from '../tooltip/Tooltip'
import MaterialSymbol from '../materialSymbol/MaterialSymbol'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	icon?: string
	label?: string
	inputSize?: string
	invalid?: boolean
	invalidMessage?: string
	warning?: boolean
	className?: string
	showTooltip?: boolean
	tooltipText?: string
	disabled?: boolean
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
	// Props
	const {
		icon,
		label,
		inputSize,
		invalid,
		invalidMessage,
		warning,
		className,
		showTooltip = false,
		tooltipText = '',
		disabled,
		...rest
	} = props

	// Classes
	const classNames =
		'Input' +
		`${className !== undefined ? ' ' + className : ''}` +
		`${inputSize ? ' Input--' + inputSize : ''}` +
		`${disabled ? ' Input--disabled' : ''}` +
		`${label ? ' Input--hasLabel' : ''}` +
		`${warning ? ' Input--warning' : ''}` +
		`${invalid ? ' Input--invalid' : ''}`

	return (
		<div className={classNames}>
			{(label || icon || showTooltip) && (
				<div className="Input_Label">
					{icon && <MaterialSymbol name={icon} fill="1" />}
					{label && <label>{label}</label>}
					{showTooltip && (
						<Tooltip content={tooltipText} disabled={tooltipText.length <= 0}>
							<MaterialSymbol className="Input_Label_Help" name="help" fill="1" />
						</Tooltip>
					)}
				</div>
			)}
			<div className="Input_Wrapper">
				<input {...rest} ref={ref} {...(disabled ? { disabled } : {})} />
			</div>

			{invalid && invalidMessage && <p className="Input_InvalidMessage"><MaterialSymbol name="cancel" fill="1" /> {invalidMessage}</p>}
		</div>
	)
})

Input.displayName = 'Input'
export default Input
