// Date helper functions
import { DateTime } from 'luxon'

// Localization
import i18n from '../i18n'
import i18next from 'i18next'

// In addition, all the times support offset arguments like "Z" and "+06:00".
// Missing lower-order values are always set to the minimum possible value; i.e. it always parses to a full DateTime. For example, "2016-05-25" parses to midnight of that day. "2016-05" parses to the first of the month, etc.
// The time is parsed as a local time if no offset is specified, but see the method docs to see your options, and also check out time zone docs for more details.
// Parses various date formats into a Luxon DateTime object.
export const parseDate = (dateTime: string | Date | DateTime | null): DateTime | null => {
	if (!dateTime) {
		console.warn('No date provided for parsing.')
		return null
	}

	// If it's already a Luxon DateTime, return it directly.
	if (DateTime.isDateTime(dateTime)) {
		return dateTime
	}

	// If it's a JavaScript Date object, convert it to a Luxon DateTime.
	if (dateTime instanceof Date) {
		return DateTime.fromJSDate(dateTime)
	}

	// If it's a string, attempt to parse it as an ISO 8601 string.
	if (typeof dateTime === 'string') {
		const parsedDateTime = DateTime.fromISO(dateTime)
		if (parsedDateTime.isValid) {
			return parsedDateTime
		}
	}

	console.warn('Provided date must be a valid ISO 8601 format, JavaScript Date object, or Luxon DateTime object.')
	return null
}

// For preset please check: https://moment.github.io/luxon/#/formatting?id=presets
export const formatDisplayDate = (dateTime: any, formatPreset = DateTime.DATE_SHORT) => {
	// Parse date
	const tmpDate = parseDate(dateTime)

	// Format date
	if (tmpDate) return tmpDate.setLocale(i18next.language).toLocaleString(formatPreset)

	return dateTime
}

// Converts date object to valid ISO string.
export const formatServerDate = (dateTime: any) => {
	// Parse date
	const tmpDate = parseDate(dateTime)

	if (tmpDate) return tmpDate.toISO()

	return tmpDate
}

// Get Luxon today's date
export const dateNow = () => DateTime.now()

// Get current javascript date with time
export const getCurrentDateWithTime = (): Date => {
	const date = new Date()
	date.setMinutes(0, 0, 0) // Reset minutes, seconds and milliseconds
	return date
}

type Time = {
	hour?: number
	minute?: number
	second?: number
	millisecond?: number
}
// Set time on a given date. If the time object is empty, it resets the time to 0:0:0.
export const modifyTime = (dateTime: string | Date | DateTime | null, time: Time | null = null): DateTime | null => {
	const parsedDateTime = parseDate(dateTime)
	if (!parsedDateTime) {
		console.error('Unable to parse the provided dateTime.')
		return null
	}

	const defaultTime: Time = { hour: 0, minute: 0, second: 0, millisecond: 0 }
	const updatedTime: Time = time ? { ...defaultTime, ...time } : defaultTime

	return parsedDateTime.set(updatedTime)
}

// Returns time part of the date as an object
export const getTimeAsObject = (dateTime: any) => {
	// Parse date
	const tmpDate = parseDate(dateTime)?.toObject()

	// Remove date part
	delete tmpDate?.year
	delete tmpDate?.month
	delete tmpDate?.day

	return tmpDate
}

// Returns time difference between now and given date.
export const getTimeAgo = (startDate: any, endDate?: any) => {
	// Parse date
	const tmpStartDate = parseDate(startDate)
	const tmpEndDate = parseDate(endDate) || DateTime.now()

	if (tmpStartDate) {
		const diff = tmpEndDate.diff(tmpStartDate, ['years', 'months', 'days', 'hours'])
		return diff.toObject()
	}

	return null
}

export const getTimeDifference = (startDate: any, endDate: any) => {
	// Parse dates
	const tmpStart = parseDate(startDate)
	const tmpEnd = parseDate(endDate)

	if (tmpStart && tmpEnd) return tmpEnd.diff(tmpStart, ['hours', 'minutes']).toObject()

	return null
}

// Returns user friendly text between now and given date
export const getDiffAsText = (targetDate: any) => {
	// Get diff object
	const diffObject = getTimeAgo(targetDate)

	// Define text
	let tmpDiffText = i18n.t('dates:startsWith')

	if (diffObject) {
		// Add years
		if (diffObject.years && diffObject.years > 0) tmpDiffText += i18n.t('dates:years', { count: diffObject?.years || 0 })

		// Add months
		if (diffObject.months && diffObject.months > 0) tmpDiffText += i18n.t('dates:months', { count: diffObject?.months || 0 })

		if (diffObject?.years === 0 && diffObject?.months === 0 && diffObject?.days === 1) {
			tmpDiffText += i18n.t('dates:yesterday')
		} else {
			// Add days
			if (diffObject.days && diffObject.days > 0) tmpDiffText += i18n.t('dates:days', { count: diffObject?.days || 0 })

			// Add hours
			if (diffObject.hours && diffObject.hours > 0)
				tmpDiffText += i18n.t('dates:hours', { count: Math.floor(diffObject?.hours) || 0 })

			tmpDiffText += i18n.t('dates:endsWith')
		}
	}

	return tmpDiffText
}
