import { useRef } from 'react'
import './ResetPassword.scss'

// Localization
import { useTranslation } from 'react-i18next'

// Template
import Public from '../../templates/public/Public'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'

// Zustand
import useUserStore from '../../../store/userStore'
import useLanguageStore from '../../../store/languageStore'

// React Hook Form
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'
import { resetPasswordSchema } from '../../../validation/resetPasswordSchema'

// Cloudflare Turnstile
import { Turnstile, type TurnstileInstance } from '@marsidev/react-turnstile'

// Utils
import { getSupportedTurnstileLanguage } from '../../../ruya-shared/shared/utils/commonHelper'

type ResetPasswordFormValues = {
	resetToken: string
	newPassword: string
	confirmNewPassword: string
	captchaToken?: string
}

const ResetPassword = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Router
	const { token } = useParams()
	const navigate = useNavigate()

	// Turnstile
	const captchaTokenRef = useRef<TurnstileInstance>(null)

	// Zustand
	const userStore = useUserStore()
	const languageStore = useLanguageStore()

	// React Hook Form
	const formOptions = { mode: 'onChange', resolver: yupResolver(resetPasswordSchema), defaultValues: { resetToken: token } } as any
	const {
		register,
		handleSubmit,
		control,
		formState: { errors, isValid }
	} = useForm<ResetPasswordFormValues>(formOptions)

	// Form submit
	const onSubmit = async (values: ResetPasswordFormValues) => {
		const result = await userStore.resetPassword(values)

		if (result) {
			navigate(commonSettings.apps.web.paths.login)
		} else {
			// Reset captcha
			captchaTokenRef.current?.reset()
		}
	}

	return (
		<ContentWrapper className="ResetPassword">
			<H1 isCentered={true}>{t('resetPassword:header')}</H1>

			<p className="ResetPassword_Desc">{t('resetPassword:explanation')}</p>

			<Form className="ResetPassword_Form" onSubmit={handleSubmit(onSubmit)} noValidate>
				<Input
					type="hidden"
					invalid={Boolean(errors?.resetToken?.message)}
					invalidMessage={errors?.resetToken?.message}
					{...register('resetToken')}
				/>

				<FormGroup>
					<Input
						icon="password"
						inputSize="lg"
						type="password"
						placeholder={t('form:newPassword.placeholder')}
						label={t('form:newPassword.label')}
						autoComplete="new-password"
						invalid={Boolean(errors?.newPassword?.message)}
						invalidMessage={errors?.newPassword?.message}
						{...register('newPassword')}
					/>
				</FormGroup>

				<FormGroup>
					<Input
						icon="password"
						inputSize="lg"
						type="password"
						placeholder={t('form:confirmNewPassword.placeholder')}
						label={t('form:confirmNewPassword.label')}
						autoComplete="new-password"
						invalid={Boolean(errors?.confirmNewPassword?.message)}
						invalidMessage={errors?.confirmNewPassword?.message}
						{...register('confirmNewPassword')}
					/>
				</FormGroup>

				<FormGroup>
					<Controller
						control={control}
						name="captchaToken"
						render={({ field }) => {
							const { onChange } = field
							return (
								<Turnstile
									ref={captchaTokenRef}
									siteKey={commonSettings.cloudFlare.turnstile.turnstileSiteKey}
									options={{
										tabIndex: 3,
										language: getSupportedTurnstileLanguage(languageStore.selectedLanguage?.isoCode)
									}}
									onError={() => captchaTokenRef.current?.reset()}
									onSuccess={token => onChange(token)}
								/>
							)
						}}
					/>
				</FormGroup>

				{userStore.error && (
					<FormGroup>
						<p className="ResetPassword_Error">{userStore.error}</p>
					</FormGroup>
				)}

				<FormGroup>
					<Button
						type="submit"
						disabled={userStore.loading || !isValid}
						loading={userStore.loading}
						text={t('button:resetPassword')}
						loadingText={t('button:saving')}
					/>
				</FormGroup>
			</Form>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</ContentWrapper>
	)
}

export default Public(ResetPassword)
