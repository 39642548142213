import { forwardRef, type TextareaHTMLAttributes, type ReactNode, type Ref } from 'react'
import './TextArea.scss'

// Localization
import { useTranslation } from 'react-i18next'

// Atoms
import Tooltip from '../tooltip/Tooltip'
import MaterialSymbol from '../materialSymbol/MaterialSymbol'

// Utils
import { copyToClipboard } from '../../../utils/commonHelper'

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	icon?: string
	label?: string
	inputSize?: string
	showCopy?: boolean
	valid?: boolean
	invalid?: boolean
	invalidMessage?: string
	warning?: boolean
	className?: string
	disabled?: boolean
	resize?: 'auto' | 'vertical' | 'horizontal'
	showTooltip?: boolean
	tooltipText?: string
}

const TextArea = (props: TextAreaProps, innerRef: Ref<HTMLTextAreaElement>) => {
	// Translation Hook
	const { t } = useTranslation()

	const {
		icon,
		label,
		inputSize,
		showCopy,
		valid,
		invalid,
		invalidMessage,
		warning,
		className,
		showTooltip = false,
		tooltipText = '',
		disabled,
		resize,
		...rest
	} = props

	// Classes
	const classNames =
		'TextArea' +
		`${className !== undefined ? ' ' + className : ''}` +
		`${inputSize ? ' TextArea--' + inputSize : ''}` +
		`${resize ? ' TextArea--' + resize : ''}` +
		`${disabled ? ' TextArea--disabled' : ''}` +
		`${label ? ' TextArea--hasLabel' : ''}` +
		`${showCopy ? ' TextArea--showCopy' : ''}` +
		`${valid ? ' TextArea--valid' : ''}` +
		`${warning ? ' TextArea--warning' : ''}` +
		`${invalid ? ' TextArea--invalid' : ''}`

	return (
		<div className={classNames} {...(icon ? { icon: icon } : {})}>
			{(label || icon || showTooltip) && (
				<div className="TextArea_Label">
					{icon && <MaterialSymbol name={icon} fill="1" />}
					{label && <label>{label}</label>}
					{showTooltip && (
						<Tooltip content={tooltipText} disabled={tooltipText.length <= 0}>
							<MaterialSymbol className="TextArea_Label_Help" name="help" fill="1" />
						</Tooltip>
					)}
				</div>
			)}

			<div className="TextArea_Wrapper">
				<textarea {...rest} ref={innerRef} {...(disabled ? { disabled } : {})} />
			</div>

			{invalid && invalidMessage && <p className="TextArea_InvalidMessage"><MaterialSymbol name="cancel" fill="1" /> {invalidMessage}</p>}
		</div>
	)
}

export default forwardRef(TextArea)
