import React, { useEffect, useState } from 'react'
import './DreamView.scss'

// Template
import Private from '../../templates/private/Private'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ContentWrapper from '../../../ruya-shared/shared/ui/atoms/contentWrapper/ContentWrapper'
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'

// Store
import useJournalStore from '../../../store/journalStore'

// Config
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

// Types
import type { IDream } from '../../../ruya-shared/shared/types'
import P from '../../../ruya-shared/shared/ui/atoms/p/P'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'
import { formatDisplayDate } from '../../../utilities/dateHelper'
import { DateTime } from 'luxon'
import H2 from '../../../ruya-shared/shared/ui/atoms/h2/H2'
import H3 from '../../../ruya-shared/shared/ui/atoms/h3/H3'

const DreamView = () => {
	// Translation Hooks
	const { t } = useTranslation()

	// Router
	const { sleepSessionId, dreamId } = useParams()
	const navigate = useNavigate()

	// Store
	const journalStore = useJournalStore()

	// Local State
	const [dream, setDream] = useState<IDream | null>(null)

	// Load dream if sleep session and dream id is provided
	useEffect(() => {
		if (sleepSessionId) {
			journalStore.loadSleepSession(sleepSessionId)
		} else {
			navigate(commonSettings.apps.web.paths.journal)
		}
	}, [sleepSessionId])

	// Load dream if sleep session and dream id is provided
	useEffect(() => {
		if (journalStore.currentSleepSession !== null && dreamId) {
			const requestedDream = journalStore.currentSleepSession?.dreams.find((dream: IDream) => dream._id === dreamId) || null

			if (requestedDream !== null) {
				setDream(requestedDream)
			}
		}
	}, [journalStore.currentSleepSession, dreamId])

	// TODO: Add to shared helper library
	const formattedDream = dream?.dream.split('\n').map((line, index) => (
		<React.Fragment key={index}>
			{line}
			<br />
		</React.Fragment>
	))

	return (
		<ContentWrapper className="DreamView">
			<div className="DreamView_Content">
				<div className="DreamView_Content_TopBar">
					<HistoryBackLink href={commonSettings.apps.web.paths.journal} />

					<Button
						color="light"
						size="sm"
						icon="edit"
						iconFill="1"
						onClick={() => navigate(`${commonSettings.apps.web.paths.journal}/dream/edit/${sleepSessionId}/${dreamId}`)}
						text={t('button:edit')}
					/>
				</div>

				<H1 className="DreamView_Content_Title">{dream?.title}</H1>

				<P className="DreamView_Content_Date">{formatDisplayDate(dream?.createdAt, DateTime.DATE_MED_WITH_WEEKDAY)}</P>

				<div className="DreamView_Content_DreamInfo">
					{dream?.isLucidDream && (
						<span>
							<MaterialSymbol name="self_improvement" /> {t('journal:lucidDream')}
						</span>
					)}
					{dream?.isColor && (
						<span>
							<MaterialSymbol name="palette" /> {t('journal:colorDream')}
						</span>
					)}
					{dream?.isRecurring && (
						<span>
							<MaterialSymbol name="sync" /> {t('journal:recurringDream')}
						</span>
					)}
				</div>

				<div className="DreamView_Content_Dream">{formattedDream}</div>

				<div>
					<H3>
						{t('journal:interpretationsSubHeader')}({dream?.interpretations?.length})
					</H3>

					<Button
						text={t('button:startInterpreting')}
						onClick={() =>
							navigate(`${commonSettings.apps.web.paths.journal}/select-interpreter/${sleepSessionId}/${dreamId}`)
						}
					/>
				</div>
			</div>
		</ContentWrapper>
	)
}

export default Private(DreamView)
