// Local Storage : Persists until explicitly deleted and does not expire.

// Get Local Storage by name
export const getLocalStorage = (name: string) => {
	return localStorage.getItem(name)
}

// Set Local Storage by name
export const setLocalStorage = (name: string, value: string) => {
	return localStorage.setItem(name, value)
}

// Remove Local Storage by name
export const removeLocalStorage = (name: string) => {
	return localStorage.removeItem(name)
}

// Clear Local Storage
export const clearLocalStorage = () => {
	return localStorage.clear()
}

// Session Storage : Changes are only available per tab and when tab close it will deleted.

// Get Session Storage by name
export const getSessionStorage = (name: string) => {
	return sessionStorage.getItem(name)
}

// Set Session Storage by name
export const setSessionStorage = (name: string, value: string) => {
	return sessionStorage.setItem(name, value)
}

// Remove Local Storage by name
export const removeSessionStorage = (name: string) => {
	return sessionStorage.removeItem(name)
}

// Clear Local Storage
export const clearSessionStorage = () => {
	return sessionStorage.clear()
}
