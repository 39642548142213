import './Tag.scss'

// Atoms
import MaterialSymbol from '../materialSymbol/MaterialSymbol'

interface TagProps {
	text?: string
	icon?: string
	iconFill?: '0' | '1'
	iconPosition?: 'left' | 'right'
	color?: 'white' | 'pink'
	className?: string
}

const Tag = (props: TagProps) => {
	const { text, icon, color = 'white', iconFill = '0', iconPosition = 'left', className, ...rest } = props

	// Classes
	const classNames =
		'Tag' +
		`${icon ? ' Tag--hasIcon' : ''}` +
		`${iconPosition === 'right' ? ' Tag--rightIcon' : ''}` +
		`${color ? ' Tag--' + color : ''}` +
		`${className !== undefined ? ' ' + className : ''}`

	return (
		<span className={classNames} {...rest}>
			{iconPosition === 'left' && icon && <MaterialSymbol name={icon} fill={iconFill} />}
			{text}
			{iconPosition === 'right' && icon && <MaterialSymbol name={icon} fill={iconFill} />}
		</span>
	)
}

export default Tag
