import './Navigation.scss'

// Localization
import { useTranslation } from 'react-i18next'

// Atoms
import ReactLink from '../../../ruya-shared/shared/ui/molecules/reactLink/ReactLink'

// Organisms
import NavigationMobile from '../navigationMobile/NavigationMobile'

// Store
import useNavigationStore from '../../../store/navigationStore'
import useLanguageStore from '../../../store/languageStore'

// Config
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

const Navigation = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Load navigation data
	const navigationStore = useNavigationStore()
	const languageStore = useLanguageStore()

	const languagePath =
		languageStore.selectedLanguage?.isoCode === languageSettings.defaultLanguage ? '' : `/${languageStore.selectedLanguage?.isoCode}`

	return (
		<nav className="Navigation">
			<ul className="Navigation_Links">
				<li>
					<ReactLink href={`${commonSettings.apps.next.url}${languagePath}`} isInternal={true}>
						{t('navigation:home')}
					</ReactLink>
				</li>
				<li>
					<ReactLink href={`${commonSettings.apps.next.url}${languagePath}/articles`} isInternal={true}>
						{t('navigation:articles')}
					</ReactLink>
				</li>
				{navigationStore.headerNavigation?.map((link: any) => (
					<li key={`Header_${link._id}`}>
						<ReactLink href={`${commonSettings.apps.next.url}${languagePath}/pages/${link.slug}`} isInternal={true}>
							{link.linkLabel}
						</ReactLink>
					</li>
				))}
				<li>
					<ReactLink href={'/journal'}>{t('navigation:journal')}</ReactLink>
				</li>
			</ul>

			<NavigationMobile />
		</nav>
	)
}

export default Navigation
